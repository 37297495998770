import React, { useEffect, useState } from "react";
import { Row, Col, Image, Stack } from "react-bootstrap";
import addIcon from "assets/images/addIcon.svg";
import 'assets/styles/common.scss';
import { FormattedMessage, useIntl } from "react-intl";
import "components/feature/Admin/CategoryManagementDetails/categoryManagementDetails.scss";
import ReusableTabs from "components/common/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ToastNotification from "components/common/Toast";
import { getCategoryManagementDataCallAPI } from "store/AdminDashboard/GetCategoryManagement/action";
import { GET_CATEGORY_MANAGEMENT_DATA } from "store/AdminDashboard/GetCategoryManagement/constant";
import CommonModal from "components/common/Modal";
import crossIcon from "assets/images/crossIcon.svg";
import { Box } from "@mui/system";
import Button from '@mui/material/Button';
import LabelWithInput from "components/common/Input/LabelWithInput";
import { getContentManagementDataCallAPI } from "store/AdminDashboard/GetContentManagement/action";
import { GET_CONTENT_MANAGEMENT_DATA } from "store/AdminDashboard/GetContentManagement/constant";
import { getFaqCallAPI } from "store/AdminDashboard/GetFAQ/action";
import { GET_FAQ } from "store/AdminDashboard/GetFAQ/constant";

const ContentManagementDetails = () => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tab, setTab] = useState(1);
    const [isError, setIsError] = useState(false);
    const [isAddModalActive, setIsAddModalActive] = useState(false);

    const contentManagementList = useSelector(state => state?.getContentManagementDataReducer?.payload?.data);
    const FaqDataList = useSelector(state => state?.getFaqDataReducer?.payload?.data);

    console.log(contentManagementList, FaqDataList)

    const [formValue, setFormValue] = useState({
        'question': '',
        'answer': '',
        'message': ''
    });

    useEffect(() => {
        getContentManagementLists();
    }, []);

    const getType = (val) => {
        switch (val) {
            case 1:
                return "about"
            case 2:
                return "privacy-policy"
            case 3:
                return "terms-conditions"
            default:
                return "about"
        }
    };

    const getContentManagementLists = (val = 1) => {
        dispatch(getContentManagementDataCallAPI(GET_CONTENT_MANAGEMENT_DATA, { type: getType(val) }, { run: true, navigate: navigate }));
    };

    const getFaqList = () => {
        dispatch(getFaqCallAPI(GET_FAQ, {}, { run: true, navigate: navigate }));
    };

    const tabData = [
        { label: intl.formatMessage({ id: "About Website" }) },
        { label: intl.formatMessage({ id: "Privacy Policy" }) },
        { label: intl.formatMessage({ id: "Terms & Conditions" }) },
        { label: intl.formatMessage({ id: "FAQs" }) },
    ];

    const handleTabChange = (val) => {
        setTab(val);
        if (val === 4) {
            getFaqList();
        } else {
            getContentManagementLists(val);
        }
    };

    const handleAddModel = () => {
        setIsAddModalActive(true);
    };

    const handleCloseAddModel = () => {
        setIsAddModalActive(false);
    };

    const handleChange = (e) => {
        const { name, value } = e?.target;
        setFormValue((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const getModalHeading = () => {
        switch (tab) {
            case 1:
                return <FormattedMessage id="Add About Website" />
            case 2:
                return <FormattedMessage id="Add Privacy Policy" />
            case 3:
                return <FormattedMessage id="Add Terms & Conditions" />
            case 4:
                return <FormattedMessage id="Add Question" />
            default:
                return <FormattedMessage id="Add About Website" />
        }
    };

    return (
        <Row className="mt-5 mt-lg-0 pb-0 m-0">
            <h5 className="heading5 mt-2 mt-lg-0 p-0"><FormattedMessage id="Content Management" /></h5>

            <Row className="mt-1 p-0 m-0">
                <Col md={12} lg={12} xl={12} xxl={8} className="m-0 p-0">
                    <ReusableTabs tabs={tabData} defaultTab={1} currentTab={(val) => handleTabChange(val)} />
                </Col>
            </Row>

            {tab === 1 &&
                <Row className="page-categoryManagement-contents mt-3 m-0 p-0 p-3">
                    <Row className="p-0 m-0 ">
                        <div className="flex items-center">
                            <p className="m-0 fs-18 fw-600 fc-000000 ff-InterSemiBold">
                                <FormattedMessage id="About Website" />
                            </p>
                        </div>
                    </Row>

                    <Row className="p-0 m-0 mt-5 pt-5 flex flex-col text-center justify-center items-center">
                        <Col lg={6}>
                            <p className="m-0 fs-20 fw-400 fc-1E1E1E ff-InterRegular">
                                <FormattedMessage id="You haven’t saved anything here yet. Click on the button below to add About website" />
                            </p>
                            <Button variant="contained" className='button mt-4' onClick={() => handleAddModel()}>
                                <Image src={addIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} className="me-2" />
                                <FormattedMessage id="Add About Website" />
                            </Button>
                        </Col>
                    </Row>

                </Row>
            }

            {tab === 2 &&
                <Row className="page-categoryManagement-contents mt-3 m-0 p-0 p-3">
                    <Row className="p-0 m-0 ">
                        <div className="flex items-center">
                            <p className="m-0 fs-18 fw-600 fc-000000 ff-InterSemiBold">
                                <FormattedMessage id="Privacy Policy" />
                            </p>
                        </div>
                    </Row>

                    <Row className="p-0 m-0 mt-5 pt-5 flex flex-col text-center justify-center items-center">
                        <Col lg={6}>
                            <p className="m-0 fs-20 fw-400 fc-1E1E1E ff-InterRegular">
                                <FormattedMessage id="You haven’t saved anything here yet. Click on the button below to add Privacy Policy" />
                            </p>
                            <Button variant="contained" className='button mt-4' onClick={() => handleAddModel()}>
                                <Image src={addIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} className="me-2" />
                                <FormattedMessage id="Add Privacy Policy" />
                            </Button>
                        </Col>
                    </Row>

                </Row>
            }

            {tab === 3 &&
                <Row className="page-categoryManagement-contents mt-3 m-0 p-0 p-3">
                    <Row className="p-0 m-0 ">
                        <div className="flex items-center">
                            <p className="m-0 fs-18 fw-600 fc-000000 ff-InterSemiBold">
                                <FormattedMessage id="Terms & Conditions" />
                            </p>
                        </div>
                    </Row>

                    <Row className="p-0 m-0 mt-5 pt-5 flex flex-col text-center justify-center items-center">
                        <Col lg={6}>
                            <p className="m-0 fs-20 fw-400 fc-1E1E1E ff-InterRegular">
                                <FormattedMessage id="You haven’t saved anything here yet. Click on the button below to add Terms & Conditions" />
                            </p>
                            <Button variant="contained" className='button mt-4' onClick={() => handleAddModel()}>
                                <Image src={addIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} className="me-2" />
                                <FormattedMessage id="Add Terms & Conditions" />
                            </Button>
                        </Col>
                    </Row>

                </Row>
            }

            {tab === 4 &&
                <Row className="page-categoryManagement-contents mt-3 m-0 p-0 p-3">
                    <Row className="p-0 m-0 ">
                        <div className="flex items-center">
                            <p className="m-0 fs-18 fw-600 fc-000000 ff-InterSemiBold">
                                <FormattedMessage id="Frequently Asked Questions" />
                            </p>
                        </div>
                    </Row>

                    <Row className="p-0 m-0 mt-5 pt-5 flex flex-col text-center justify-center items-center">
                        <Col lg={6}>
                            <p className="m-0 fs-20 fw-400 fc-1E1E1E ff-InterRegular">
                                <FormattedMessage id="You haven’t saved anything here yet. Click on the button below to add a question" />
                            </p>
                            <Button variant="contained" className='button mt-4' onClick={() => handleAddModel()}>
                                <Image src={addIcon} alt={"icon"} style={{ height: '24px', width: '24px' }} className="me-2" />
                                <FormattedMessage id="Add Add Question" />
                            </Button>
                        </Col>
                    </Row>

                </Row>
            }

            {/* Add Modal  */}
            <CommonModal padding={'32px 48px'} width={'736px'} modalActive={isAddModalActive} handleClose={() => handleCloseAddModel()}
                innerContent={
                    <Box >
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="flex justify-between">
                                    <h2 className="heading2">
                                        {getModalHeading()}
                                    </h2>
                                    <Image src={crossIcon} alt={"icon"} style={{ cursor: "pointer", height: '32px', width: '32px' }} onClick={() => handleCloseAddModel()} />
                                </div>
                            </Col>

                            {tab === 4 ?
                                <>
                                    <Col xs={12} md={12} className='mt-2'>
                                        <LabelWithInput
                                            label={<FormattedMessage id="Question" />}
                                            placeholder={intl.formatMessage({ id: "Question" })}
                                            name="question"
                                            type="text"
                                            value={formValue?.question}
                                            onChange={(e) => handleChange(e)}
                                            errorText={isError && isError?.question?.error && isError?.question?.errorMessage}
                                        />
                                    </Col>
                                    <Col xs={12} md={12} className='mt-2'>
                                        <LabelWithInput
                                            label={<FormattedMessage id="Answer" />}
                                            placeholder={intl.formatMessage({ id: "Answer" })}
                                            height="150"
                                            multiline
                                            name="answer"
                                            value={formValue?.answer}
                                            onChange={(e) => handleChange(e)}
                                            errorText={isError && isError?.answer?.error && isError?.answer?.errorMessage}
                                        />
                                    </Col>
                                </>
                                :
                                <>
                                    <Col xs={12} md={12} className='mt-2'>
                                        <LabelWithInput
                                            placeholder={intl.formatMessage({ id: "Write Something here ...." })}
                                            marginTop="0"
                                            height="350"
                                            multiline
                                            name="message"
                                            value={formValue?.message}
                                            onChange={(e) => handleChange(e)}
                                            errorText={isError && isError?.message?.error && isError?.message?.errorMessage}
                                        />
                                    </Col>
                                </>
                            }
                        </Row>

                        <Row className="flex flex-col items-end">
                            <Col xs={6} md={6} className='flex'>
                                <Button variant="outlined" className='cancelButton mt-5 md:mt-2 w-100 me-3' onClick={() => handleCloseAddModel()}><FormattedMessage id="Cancel" /></Button>
                                <Button variant="contained" className='button mt-5 md:mt-2 w-100' ><FormattedMessage id="save" /></Button>
                            </Col>
                        </Row>
                    </Box>
                }
            />
            {/* Add Modal  */}

        </Row>
    );
};

export default ContentManagementDetails;
