import { useContext, useRef, useState } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { LocaleContext } from "routes/Router";
import styled from 'styled-components';
import frameEn from "assets/images/welcomeFrame.png";
import frameAr from "assets/images/welcomeFrameAr.svg";
import phoneColorIcon from "assets/images/Navbar/phoneColorIcon.png";
import mailColorIcon from "assets/images/Navbar/mailColorIcon.png";
import locationColorIcon from "assets/images/Navbar/locationColorIcon.png";
import facebookWhiteColorIcon from "assets/images/Navbar/facebookWhiteColorIcon.png";
import twitterWhiteColorIcon from "assets/images/Navbar/twitterWhiteColorIcon.png";
import InstaWhiteColorIcon from "assets/images/Navbar/InstaWhiteColorIcon.png";
import LabelWithInput from "components/common/Input/LabelWithInput";
import Button from '@mui/material/Button';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { contactUsCallApi } from "store/StudentDashboard/ContactUs/action";
import { CONTACT_US } from "store/StudentDashboard/ContactUs/constant";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ToastNotification from "components/common/Toast";
import { getLandingURL } from "routes/AppUrls";

const WrapperDiv = styled.div`
    background: ${(props) => props?.locale === "en" ? `url(${frameEn}) no-repeat center center` : `url(${frameAr}) no-repeat center center`};
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const WelcomeTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;


const ContactUsScreen = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { locale } = useContext(LocaleContext);
    const intl = useIntl();
    const [isError, setIsError] = useState(false);
    const [conf, setConf] = ToastNotification();

    const [formValue, setformValue] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        message: ""
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setformValue((prev) => ({
            ...prev,
            [name]: value
        }))
    };

    const handleSubmit = () => {
        let body = {
            "firstname": formValue.firstName,
            "lastname": formValue.lastName,
            "email": formValue.email,
            "isd_code": countryCode,
            "phone": formValue.phoneNumber,
            "message": formValue.message
        };
        dispatch(contactUsCallApi(CONTACT_US, body, { run: true, handleFunction: (res) => handleSuccess(res), navigate: navigate, handleToastError: (res) => ToastErrorFunction(res), toast: true }))
    };

    const handleSuccess = (res) => {
        if (res?.data?.status_code === 200) {
            setConf({ msg: 'Successfully Submitted', variant: 'success' });
            navigate(getLandingURL(locale))
        } else {
            setConf({ msg: res?.data?.data, variant: 'error' });
        }
    };

    const ToastErrorFunction = (res) => {
        setConf({ msg: 'All fields are required!', variant: 'error' });
    };

    ////////////////////////////////countryCode///////////////////////////////

    const [countryCode, setCountryCode] = useState('');
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const phoneNumberInputRef = useRef(null);

    const handleCountryCodeChange = (value, country) => {
        setCountryCode(`+${country.dialCode}`);
        phoneNumberInputRef.current.focus();
    };

    const handlePhoneNumberChange = (e) => {
        const phoneNumberValue = e.target.value;
        setformValue((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));

        const fullPhoneNumber = `${countryCode}${phoneNumberValue}`;
        const parsedPhoneNumber = parsePhoneNumberFromString(fullPhoneNumber);

        if (parsedPhoneNumber) {
            setIsPhoneNumberValid(parsedPhoneNumber.isValid());
        } else {
            setIsPhoneNumberValid(false);
        }
    };
    ////////////////////////////////countryCode///////////////////////////////

    return (
        <Container fluid>
            <Row >
                <Col lg={6} style={{ padding: 0, margin: 0 }}>
                    <WrapperDiv locale={locale}>
                        <Row className="mt-2 pt-0 mt-lg-5 pt-lg-5">
                            <Col xs={12} md={12} lg={12} >
                                <WelcomeTextContainer className={`m-20 ${locale === "en" ? "xl:ml-140" : "xl:mr-140"}`}>
                                    <h2 className='layout'>{<FormattedMessage id="Contact Us" />}</h2>

                                    <Row className="mt-5">
                                        <h2 className="fs-24 fc-911E59 fw-600 ff-LexendDecaSemibold"><FormattedMessage id="Contact Information" /></h2>
                                        <Row>
                                            <Col lg={9} className="mt-1">
                                                <Stack direction="horizontal" gap={2} className="flex items-center">
                                                    <img src={mailColorIcon} alt="icon" style={{ height: "32px", width: "32px" }} />
                                                    <p className="fs-18 fw-400 fc-1E1E1E ff-InterRegular m-0">{'contactmail1@mazad.com'} | {'contactmail2@mazad.com'}</p>
                                                </Stack>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={9} className="mt-4">
                                                <Stack direction="horizontal" gap={2} className="items-center">
                                                    <img src={phoneColorIcon} alt="icon" style={{ height: "32px", width: "32px" }} />
                                                    <p className="fs-18 fw-400 fc-1E1E1E ff-InterRegular m-0">{'+44 3214569871'} | {'+01 3214569871'}</p>
                                                </Stack>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={9} className="mt-4">
                                                <Stack direction="horizontal" gap={2} className="d-flex align-items-start">
                                                    <img src={locationColorIcon} alt="icon" style={{ height: "32px", width: "32px" }} />
                                                    <p className="fs-18 fw-400 fc-1E1E1E ff-InterRegular m-0">{'40236 Parker Corners, Jadeborough 10775-0149 Grant Garden, North Sisterburgh 18177-9926'}</p>
                                                </Stack>
                                            </Col>
                                        </Row>
                                    </Row>

                                    <Row className="d-flex mt-5">
                                        <h2 className="fs-20 fc-282938 fw-600 ff-LexendDecaSemibold"><FormattedMessage id="Socials" /></h2>
                                        <Stack direction="horizontal" gap={3} className="items-center mt-3">
                                            <img src={facebookWhiteColorIcon} alt="icon" style={{ height: "48px", width: "48px" }} />
                                            <img src={twitterWhiteColorIcon} alt="icon" style={{ height: "48px", width: "48px" }} />
                                            <img src={InstaWhiteColorIcon} alt="icon" style={{ height: "48px", width: "48px" }} />
                                        </Stack>
                                    </Row>
                                </WelcomeTextContainer>
                            </Col>
                        </Row>
                    </WrapperDiv>
                </Col>

                <Col lg={6} sm={12} className="right_Container">
                    <Row>
                        <Col xxl={1}>
                        </Col>
                        <Col xxl={10}>
                            <Container>
                                <Row className='pt-20  md:pt-120 '>
                                    <Row >
                                        <Col>
                                            <h2 className='heading2'><FormattedMessage id="Let’sTalk" /></h2>
                                        </Col>
                                    </Row>

                                    <Col xs={12} md={6} className='mt-4'>
                                        <LabelWithInput
                                            label={<FormattedMessage id="firstName" />}
                                            placeholder={intl.formatMessage({ id: "firstName" })}
                                            name="firstName"
                                            type="text"
                                            value={formValue?.firstName}
                                            onChange={handleInputChange}
                                            errorText={isError && isError?.firstName?.error && isError?.firstName?.errorMessage}
                                        />
                                    </Col>

                                    <Col xs={12} md={6} className='mt-4'>
                                        <LabelWithInput
                                            label={<FormattedMessage id="lastName" />}
                                            placeholder={intl.formatMessage({ id: "lastName" })}
                                            name="lastName"
                                            type="text"
                                            value={formValue?.lastName}
                                            onChange={handleInputChange}
                                            errorText={isError && isError?.lastName?.error && isError?.lastName?.errorMessage}
                                        />
                                    </Col>

                                    <Col xs={12} md={6} className='mt-4'>
                                        <LabelWithInput
                                            label={<FormattedMessage id="EmailId" />}
                                            placeholder={intl.formatMessage({ id: "EmailId" })}
                                            name="email"
                                            type="text"
                                            value={formValue?.email}
                                            onChange={handleInputChange}
                                            errorText={isError && isError?.email?.error && isError?.email?.errorMessage}
                                        />
                                    </Col>

                                    <Col xs={12} md={6} className='mt-4'>
                                        <div className="phone-number-container">
                                            <label className="phone-label"><FormattedMessage id="PhoneNumber" /></label>
                                            <div className="phone-input-wrapper">

                                                <div className={locale === "en" ? "phone-en-react me-4 ms-0" : "phone-ar-react ms-4 me-0"}>
                                                    <PhoneInput
                                                        country={'qa'}
                                                        value={countryCode}
                                                        onChange={handleCountryCodeChange}
                                                        inputProps={{
                                                            name: 'countryCode',
                                                            required: true,
                                                        }}
                                                        placeholder="country"
                                                        inputClass="custom-country-input"
                                                        disableCountryCode={true}
                                                        disableDropdown={false}
                                                        onlyCountries={['qa', 'us', 'in', 'gb']}
                                                    />
                                                </div>

                                                <div className="phone-number-input">
                                                    <input
                                                        type="number"
                                                        name="phoneNumber"
                                                        value={formValue?.phoneNumber}
                                                        onChange={handlePhoneNumberChange}
                                                        ref={phoneNumberInputRef}
                                                        placeholder="e.g: 8521364783"
                                                        className={`custom-phone-input ${!isPhoneNumberValid ? 'invalid' : ''}`}
                                                    />
                                                </div>
                                            </div>

                                            {isError && isError?.phoneNumber?.error && isError?.phoneNumber?.errorMessage ?
                                                <p className="error-message">{isError?.phoneNumber?.errorMessage}</p>
                                                :
                                                !isPhoneNumberValid ?
                                                    <p className="error-message"><FormattedMessage id="Invalidphonenumberforselectedcountrycode" /></p>
                                                    :
                                                    ""
                                            }

                                        </div>
                                    </Col>

                                    <Col xs={12} md={12} className='mt-2 mb-5'>
                                        <LabelWithInput
                                            label={<FormattedMessage id="Message" />}
                                            placeholder={intl.formatMessage({ id: "Message" })}
                                            name="message"
                                            type="text"
                                            height="118"
                                            multiline
                                            value={formValue?.message}
                                            onChange={handleInputChange}
                                            errorText={isError && isError?.message?.error && isError?.message?.errorMessage}
                                        />
                                    </Col>
                                </Row>

                                <Row className="footer pb-2">
                                    <Button variant="contained" className='button' onClick={() => handleSubmit()}><FormattedMessage id="Submit" /></Button>
                                </Row>
                            </Container>
                        </Col>
                        <Col xxl={1}>
                        </Col>
                    </Row>
                </Col>
            </Row >
        </Container >
    )
}

export default ContactUsScreen;