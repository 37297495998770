import { combineReducers } from "redux";
import countryDropDownReducer from "store/Auth/Registration/Country/reducer";
import stateDropDownReducer from "store/Auth/Registration/State/reducer";
import cityDropDownReducer from "store/Auth/Registration/City/reducer";
import qualificationDropDownReducer from "store/Auth/Registration/Qualification/reducer";
import teachingMaterialDropDownReducer from "store/Auth/Registration/TeachingMaterial/reducer";
import gradeLevelDropDownReducer from "store/Auth/Registration/GreadeLevel/reducer";
import languageDropDownReducer from "store/Auth/Registration/Language/reducer";
import subjectDropDownReducer from "store/Auth/Registration/Subject/reducer";
import authRegisterReducer from "store/Auth/Registration/Auth/reducer";
import authLoginReducer from "store/Auth/Login/reducer";
import authLogoutReducer from "store/Auth/Logout/reducer";
import getProfileReducer from "store/Dashboard/Profile/reducer";
import editBioReducer from "store/Dashboard/Bio/reducer";
import addQualificationReducer from "store/Dashboard/Qualification/reducer";
import editProfileReducer from "store/Dashboard/EditProfile/reducer";
import addTeacherSubjectReducer from "store/Dashboard/AddTeacherSubject/reducer";
import editProfileImageReducer from "store/Dashboard/EditProfileImage/reducer";
import authResendReducer from "store/Auth/Resend/reducer";
import authEmailVerificationReducer from "store/Auth/Forgot/EmailVerfication/reducer";
import authOTPVerificationReducer from "store/Auth/Forgot/OtpVerification/reducer";
import authResetPasswordReducer from "store/Auth/Forgot/ResetPassword/reducer";
import RegistrationDetailsReducer from "store/BasicDetails/RegisterationDetails/reducer";
import editQualificationReducer from "store/Dashboard/EditQualification/reducer";
import assessmentVerificationReducer from "store/Dashboard/AssessmentVerifiedEmail/reducer";
import getQuestionsReducer from "store/Dashboard/GetQuestions/reducer";
import SubmitQuestionsReducer from "store/Dashboard/SubmitQuestions/reducer";
import getTeacherAvailabilityReducer from "store/Dashboard/GetTeacherAvailability/reducer";
import addTeacherAvailabilityReducer from "store/Dashboard/AddTeacherAvailability/reducer";
import repeatFrequencyDropDownReducer from "store/Dashboard/Availability/RepeatFrequency/reducer";
import weekDaysDropDownReducer from "store/Dashboard/Availability/WeekDays/reducer";
import getAvailableTeacherListReducer from "store/Dashboard/GetAvailableTeacherList/reducer";
import bookInterViewSlotReducer from "store/Dashboard/BookInterViewSlot/reducer";
import basicDetailsReducer from "store/BasicDetails/basicDetails/reducer";
import getInterviewFeedbackReducer from "store/Dashboard/InterViewFeedback/reducer";
import editTeacherAvailabilityReducer from "store/Dashboard/EditTeacherAvailability/reducer";
import daleteTeacherAvailabilityReducer from "store/Dashboard/DeleteTeacherAvailability/reducer";
import getAllStudentsListReducer from "store/AdminDashboard/GetAllStudentLists/reducer";
import getAllTeachersListReducer from "store/AdminDashboard/GetAllTeacherLists/reducer";
import updateUserStatusReducer from "store/AdminDashboard/UpdateUserStatus/reducer";
import getAllInternalTeachersListReducer from "store/AdminDashboard/GetAllInternalTeacherLists/reducer";
import updateUserDataReducer from "store/AdminDashboard/UpdateUserData/reducer";
import markInternalTeacherReducer from "store/AdminDashboard/MarkInternalTeacher/reducer";
import getCategoryManagementDataReducer from "store/AdminDashboard/GetCategoryManagement/reducer";
import addSubjectGradeReducer from "store/AdminDashboard/AddSubjectGradeAdmin/reducer";
import editSubjectGradeReducer from "store/AdminDashboard/EditSubjectGradeAdmin/reducer";
import deleteSubjectGradeReducer from "store/AdminDashboard/DeleteSubjectGradeAdmin/reducer";
import deviceTypeDropdownReducer from "store/AdminDashboard/DeviceTypeDropDown/reducer";
import userTypeDropdownReducer from "store/AdminDashboard/UserTypeDropDown/reducer";
import pushNotificationReducer from "store/AdminDashboard/PushNotification/reducer";
import getManagePlanReducer from "store/AdminDashboard/GetManagePlan/reducer";
import addManagePlanReducer from "store/AdminDashboard/AddManagePlan/reducer";
import editManagePlanReducer from "store/AdminDashboard/EditmanagePlan/reducer";
import planTypeDropdownReducer from "store/AdminDashboard/PlanTypeDropDown/reducer";
import billingCycleDropdownReducer from "store/AdminDashboard/BillingCycleDropDown/reducer";
import deleteManagePlanReducer from "store/AdminDashboard/DeleteManagePlan/reducer";
import teacherSearchReducer from "store/StudentDashboard/TeacherSearch/reducer";
import teacherFullProfileReducer from "store/StudentDashboard/TeacherFullProfile/reducer";
import studentReviewReducer from "store/StudentDashboard/StudentReview/reducer";
import searchUserByFieldReducer from "store/AdminDashboard/SearchUserByFiled/reducer";
import packagePlanReducer from "store/Auth/Registration/PackagePlan/reducer";
import contactUsReducer from "store/StudentDashboard/ContactUs/reducer";
import featuredListReducer from "store/StudentDashboard/FeaturedList/reducer";
import supportListReducer from "store/AdminDashboard/SupportList/reducer";
import adminProfileReducer from "store/AdminDashboard/AdminProfile/reducer";
import getContentManagementDataReducer from "store/AdminDashboard/GetContentManagement/reducer";
import addContentManagementDataReducer from "store/AdminDashboard/AddContentManagement/reducer";
import getFaqDataReducer from "store/AdminDashboard/GetFAQ/reducer";

export default combineReducers({
    countryDropDownReducer,
    stateDropDownReducer,
    cityDropDownReducer,
    qualificationDropDownReducer,
    teachingMaterialDropDownReducer,
    gradeLevelDropDownReducer,
    languageDropDownReducer,
    subjectDropDownReducer,
    authRegisterReducer,
    authLoginReducer,
    authLogoutReducer,
    getProfileReducer,
    editBioReducer,
    addQualificationReducer,
    editProfileReducer,
    addTeacherSubjectReducer,
    editProfileImageReducer,
    authResendReducer,
    authEmailVerificationReducer,
    authOTPVerificationReducer,
    authResetPasswordReducer,
    RegistrationDetailsReducer,
    editQualificationReducer,
    assessmentVerificationReducer,
    getQuestionsReducer,
    SubmitQuestionsReducer,
    getTeacherAvailabilityReducer,
    addTeacherAvailabilityReducer,
    repeatFrequencyDropDownReducer,
    weekDaysDropDownReducer,
    getAvailableTeacherListReducer,
    bookInterViewSlotReducer,
    basicDetailsReducer,
    getInterviewFeedbackReducer,
    editTeacherAvailabilityReducer,
    daleteTeacherAvailabilityReducer,
    getAllStudentsListReducer,
    getAllTeachersListReducer,
    updateUserStatusReducer,
    getAllInternalTeachersListReducer,
    updateUserDataReducer,
    markInternalTeacherReducer,
    getCategoryManagementDataReducer,
    addSubjectGradeReducer,
    editSubjectGradeReducer,
    deleteSubjectGradeReducer,
    deviceTypeDropdownReducer,
    userTypeDropdownReducer,
    pushNotificationReducer,
    getManagePlanReducer,
    addManagePlanReducer,
    editManagePlanReducer,
    planTypeDropdownReducer,
    billingCycleDropdownReducer,
    deleteManagePlanReducer,
    teacherSearchReducer,
    teacherFullProfileReducer,
    studentReviewReducer,
    searchUserByFieldReducer,
    packagePlanReducer,
    contactUsReducer,
    featuredListReducer,
    supportListReducer,
    adminProfileReducer,
    getContentManagementDataReducer,
    addContentManagementDataReducer,
    getFaqDataReducer,
})
