import { all, fork } from "redux-saga/effects";
import authLoginSaga from "./Auth/Login/saga";
import authLogoutSaga from "./Auth/Logout/saga";
import authResendSaga from "./Auth/Resend/saga";
import addTeacherSubjectSaga from "./Dashboard/AddTeacherSubject/saga";
import editBioSaga from "./Dashboard/Bio/saga";
import editProfileSaga from "./Dashboard/EditProfile/saga";
import editProfileImageSaga from "./Dashboard/EditProfileImage/saga";
import getProfileSaga from "./Dashboard/Profile/saga";
import addQualificationSaga from "./Dashboard/Qualification/saga";
import authRegisterSaga from "./Auth/Registration/Auth/saga";
import cityDropDownSaga from "./Auth/Registration/City/saga";
import countryDropDownSaga from "./Auth/Registration/Country/saga";
import gradeLevelDropDownSaga from "./Auth/Registration/GreadeLevel/saga";
import languageDropDownSaga from "./Auth/Registration/Language/saga";
import qualificationDropDownSaga from "./Auth/Registration/Qualification/saga";
import stateDropDownSaga from "./Auth/Registration/State/saga";
import subjectDropDownSaga from "./Auth/Registration/Subject/saga";
import teachingMaterialDropDownSaga from "./Auth/Registration/TeachingMaterial/saga";
import authEmailVerificationSaga from "./Auth/Forgot/EmailVerfication/saga";
import authOTPVerificationSaga from "./Auth/Forgot/OtpVerification/saga";
import authResetPasswordSaga from "./Auth/Forgot/ResetPassword/saga";
import editQualificationSaga from "./Dashboard/EditQualification/saga";
import assessmentVerificationSaga from "./Dashboard/AssessmentVerifiedEmail/saga";
import getQuestionsSaga from "./Dashboard/GetQuestions/saga";
import submitQuestionsSaga from "./Dashboard/SubmitQuestions/saga";
import getTeacherAvailabilitySaga from "./Dashboard/GetTeacherAvailability/saga";
import addTeacherAvailabilitySaga from "./Dashboard/AddTeacherAvailability/saga";
import repeatFrequencyDropDownSaga from "./Dashboard/Availability/RepeatFrequency/saga";
import weekDaysDropDownSaga from "./Dashboard/Availability/WeekDays/saga";
import getAvailableTeacherListSaga from "./Dashboard/GetAvailableTeacherList/saga";
import bookInterViewSlotSaga from "./Dashboard/BookInterViewSlot/saga";
import getInterviewFeedbackSaga from "./Dashboard/InterViewFeedback/saga";
import editTeacherAvailabilitySaga from "./Dashboard/EditTeacherAvailability/saga";
import deleteTeacherAvailabilitySaga from "./Dashboard/DeleteTeacherAvailability/saga";
import getAllStudentsListSaga from "./AdminDashboard/GetAllStudentLists/saga";
import getAllTeachersListSaga from "./AdminDashboard/GetAllTeacherLists/saga";
import updateUserStatusSaga from "./AdminDashboard/UpdateUserStatus/saga";
import getAllInternalTeachersListSaga from "./AdminDashboard/GetAllInternalTeacherLists/saga";
import updateUserDataSaga from "./AdminDashboard/UpdateUserData/saga";
import markInternalTeacherSaga from "./AdminDashboard/MarkInternalTeacher/saga";
import getCategoryManagementDataSaga from "./AdminDashboard/GetCategoryManagement/saga";
import addSubjectGradeSaga from "./AdminDashboard/AddSubjectGradeAdmin/saga";
import deleteSubjectGradeSaga from "./AdminDashboard/DeleteSubjectGradeAdmin/saga";
import editSubjectGradeSaga from "./AdminDashboard/EditSubjectGradeAdmin/saga";
import deviceTypeDropdownSaga from "./AdminDashboard/DeviceTypeDropDown/saga";
import userTypeDropdownSaga from "./AdminDashboard/UserTypeDropDown/saga";
import pushNotificationSaga from "./AdminDashboard/PushNotification/saga";
import getManagePlanSaga from "./AdminDashboard/GetManagePlan/saga";
import addManagePlanSaga from "./AdminDashboard/AddManagePlan/saga";
import editManagePlanSaga from "./AdminDashboard/EditmanagePlan/saga";
import planTypeDropdownSaga from "./AdminDashboard/PlanTypeDropDown/saga";
import billingCycleDropdownSaga from "./AdminDashboard/BillingCycleDropDown/saga";
import deleteManagePlanSaga from "./AdminDashboard/DeleteManagePlan/saga";
import teacherSearchSaga from "./StudentDashboard/TeacherSearch/saga";
import teacherFullProfileSaga from "./StudentDashboard/TeacherFullProfile/saga";
import studentReviewSaga from "./StudentDashboard/StudentReview/saga";
import searchUserByFieldSaga from "./AdminDashboard/SearchUserByFiled/saga";
import packagePlanSaga from "./Auth/Registration/PackagePlan/saga";
import contactUsSaga from "./StudentDashboard/ContactUs/saga";
import featuredListSaga from "./StudentDashboard/FeaturedList/saga";
import supportListSaga from "./AdminDashboard/SupportList/saga";
import adminProfileSaga from "./AdminDashboard/AdminProfile/saga";
import getContentManagementDataSaga from "./AdminDashboard/GetContentManagement/saga";
import addContentManagementDataSaga from "./AdminDashboard/AddContentManagement/saga";
import getFaqDataSaga from "./AdminDashboard/GetFAQ/saga";

const rootSaga = function* () {
   yield all([
      fork(countryDropDownSaga),
      fork(stateDropDownSaga),
      fork(cityDropDownSaga),
      fork(qualificationDropDownSaga),
      fork(teachingMaterialDropDownSaga),
      fork(gradeLevelDropDownSaga),
      fork(languageDropDownSaga),
      fork(subjectDropDownSaga),
      fork(authRegisterSaga),
      fork(authLoginSaga),
      fork(authLogoutSaga),
      fork(getProfileSaga),
      fork(editBioSaga),
      fork(addQualificationSaga),
      fork(editProfileSaga),
      fork(addTeacherSubjectSaga),
      fork(editProfileImageSaga),
      fork(authResendSaga),
      fork(authEmailVerificationSaga),
      fork(authOTPVerificationSaga),
      fork(authResetPasswordSaga),
      fork(editQualificationSaga),
      fork(assessmentVerificationSaga),
      fork(getQuestionsSaga),
      fork(submitQuestionsSaga),
      fork(getTeacherAvailabilitySaga),
      fork(addTeacherAvailabilitySaga),
      fork(repeatFrequencyDropDownSaga),
      fork(weekDaysDropDownSaga),
      fork(getAvailableTeacherListSaga),
      fork(bookInterViewSlotSaga),
      fork(getInterviewFeedbackSaga),
      fork(editTeacherAvailabilitySaga),
      fork(deleteTeacherAvailabilitySaga),
      fork(getAllStudentsListSaga),
      fork(getAllTeachersListSaga),
      fork(updateUserStatusSaga),
      fork(getAllInternalTeachersListSaga),
      fork(updateUserDataSaga),
      fork(markInternalTeacherSaga),
      fork(getCategoryManagementDataSaga),
      fork(addSubjectGradeSaga),
      fork(editSubjectGradeSaga),
      fork(deleteSubjectGradeSaga),
      fork(deviceTypeDropdownSaga),
      fork(userTypeDropdownSaga),
      fork(pushNotificationSaga),
      fork(getManagePlanSaga),
      fork(addManagePlanSaga),
      fork(editManagePlanSaga),
      fork(planTypeDropdownSaga),
      fork(billingCycleDropdownSaga),
      fork(deleteManagePlanSaga),
      fork(teacherSearchSaga),
      fork(teacherFullProfileSaga),
      fork(studentReviewSaga),
      fork(searchUserByFieldSaga),
      fork(packagePlanSaga),
      fork(contactUsSaga),
      fork(featuredListSaga),
      fork(supportListSaga),
      fork(adminProfileSaga),
      fork(getContentManagementDataSaga),
      fork(addContentManagementDataSaga),
      fork(getFaqDataSaga),
   ]);
};

export default rootSaga;